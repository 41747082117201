section {
    margin: 20px 0;
}

.vLine {
    display: block;
    height: 24px;
    width: 2px;
    background: #274A6A;
    align-items: center;
    margin: -12px 0;
}

.timeline {
    display: block;
    width: 100%;
    margin-top: 35px;
    padding: 15px 0;
}

.timeline-line {
    background: #274A6A;
    height: 2px;
    border-radius: 12px;
    position: relative;
}

.timeline-point {
    border: none;
    position: absolute;

    border-radius: 50%;
    background: #274A6A;
    width: 16px;
    height: 16px;

    top: -40px;
    left: 50%;
}

.timeline > ul {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
}

.timeline > li {
    margin-top: 0px;
    position: relative;
    width: 100%;
    padding: 0px;
    text-align: center;
}

.timeline > li p {
    padding: 0 20px;
    color: black;
    font-size: 0.666rem;
    font-weight: 400;
    line-height: 0.5em;
}
/* .date {
    position: absolute;

    top: -60px;
    left: -10%;
    opacity: 0;
    font-size: 12px;
} */