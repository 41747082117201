#ac-chart-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

}

#Phase,
#Age,
#Size{
    width: inherit;
    flex-grow: 1;

}

.anychart-credits {
    display: none;
}
